import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Recharge POS";
  }, []);

  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    if (fname == "") {
      Swal.fire("Opps!", "Please Enter First Name", "error");
      return;
    }

    if (lname == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (email == "") {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (mobile == "") {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (subject == "") {
      Swal.fire("Opps!", "Please Enter Subject", "error");
      return;
    }

    if (message == "") {
      Swal.fire("Opps!", "Please Enter Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        fname + " " + lname,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style3" />

        <div className="inner-hero-warp">
          <div className="container">
            <div className="inner-hero-content">
              <h1>Contact Us</h1>
              <p>Let's Connect – We're Here to Help!</p>
            </div>
          </div>
        </div>

        {/* Contact Warp Start */}
        <div className="contact-warp ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-content single-section">
                  <span className="features-title">Contact Us</span>
                  <h2>Get In Touch With Us</h2>
                  <p>
                    Have Questions or Need Assistance? Reach Out to Our Team for
                    Prompt Support and Solutions.
                  </p>
                  <div className="contact-widget d-flex align-items-center ">
                    <div className="icon-style">
                      <div className="icon">
                        <img
                          src="assets/images/svgs/contact1.svg"
                          className="contact1"
                          alt="contact1"
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5>Helpdesk Email</h5>
                      <a href="#">
                        <span>helpdesk@rechargepos.com</span>
                      </a>
                    </div>
                  </div>
                  <div className="contact-widget d-flex align-items-center ">
                    <div className="icon-style">
                      <div className="icon">
                        <img
                          src="assets/images/svgs/contact2.svg"
                          className="contact1"
                          alt="contact1"
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5>Customer Service Number</h5>
                      <a href="tel:917890251220">7890-25-1220</a>
                    </div>
                  </div>
                  <div className="contact-widget d-flex align-items-center ">
                    <div className="icon-style">
                      <div className="icon">
                        <img
                          src="assets/images/svgs/contact3.svg"
                          className="contact1"
                          alt="contact1"
                        />
                      </div>
                    </div>
                    <div className="content">
                      <h5>Location</h5>
                      <span>Barasat, Kolkata - 700126</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="massage-warp">
                  {/* <h3>Send A Message</h3> */}
                  <form>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            First Name
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Last Name
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Last Name"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Phone
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Phone"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label
                            htmlFor="exampleFormControlInput1"
                            className="form-label"
                          >
                            Your Message
                          </label>
                          <textarea
                            type="email"
                            className="form-control"
                            placeholder="Type Your Text Here......."
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <button
                          className="default-btn heading-color"
                          type="button"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Warp End */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
