import common from "../utill/Common";

class FormService {
  contactForm = async (name, email, mobile, subject, message) => {
    const url = common.baseUrl + "form/contact";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, mobile, subject, message }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

export default new FormService();
