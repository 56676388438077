import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        {/* Footer Warp Start */}
        <div className="footer-warp pt-100 pb-75 bg-F8F9FC">
          <div className="container-fluid style3">
            <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget">
                  <h4>
                    <a href="index.html">
                      <img src="/images/logo.png" alt="logo" width={200} />
                    </a>
                  </h4>

                  <p style={{ textAlign: "justify" }}>
                    Recharge POS is a fast, secure platform for mobile
                    recharges, DTH payments, and utility bills. Earn commissions
                    on every transaction and enjoy a seamless payment
                    experience.
                  </p>

                  <div style={{ paddingTop: "20px" }}>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.fispl.rechargepos"
                      target="_blank"
                    >
                      <img
                        src="/images/google-play.png"
                        alt="logo"
                        width={150}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget style2">
                  <h4>Our Services</h4>
                  <ul className="footer-link">
                    <li>
                      <a href="/our_services">Mobile Recharge</a>
                    </li>
                    <li>
                      <a href="/our_services">DTH Recharge</a>
                    </li>
                    <li>
                      <a href="/our_services">Google Play Recharge</a>
                    </li>
                    <li>
                      <a href="/our_services">Utility Bill Payments</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget style2">
                  <h4>Useful Links</h4>
                  <ul className="footer-link">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/about_us">About Us </a>
                    </li>
                    <li>
                      <a href="/contact_us">Contact Us</a>
                    </li>
                    <li>
                      <a href="/terms_and_conditions">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="/privacy_policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="/refund_policy">Refund Policy</a>
                    </li>
                    <li>
                      <a href="/grievance_policy">Grievance Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="footer-widget style2">
                  <h4>Get In Touch</h4>
                  <ul className="footer-contact">
                    <li>
                      <span>Customer Service Number</span>
                      <a href="tel:917890251220">7890-25-1220</a>
                    </li>
                    <li>
                      <span>Helpdesk Email</span>
                      <a href="">
                        <span>helpdesk@rechargepos.com</span>
                      </a>
                    </li>
                    <li>
                      <span>Location</span>
                      <p>Barasat, Kolkata - 700126</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Warp End */}
        {/* Copyright Warp Start */}
        <div className="copyright-style2-warp bg-F8F9FC style6">
          <div className="container">
            <div className="inner-copyright-warp d-flex align-items-center justify-content-between">
              <p>Copyright © 2024 Recharge POS</p>
              <ul className="social-list">
                <li>
                  <a
                    href="https://www.facebook.com/rechargepos"
                    target="_blank"
                  >
                    <i className="ri-facebook-circle-line" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://whatsapp.com/channel/0029VarvArf1dAwASjmuD62n"
                    target="_blank"
                  >
                    <i className="ri-whatsapp-line" />
                  </a>
                </li>
                <li>
                  <a href="https://t.me/rechargepos" target="_blank">
                    <i className="ri-telegram-line" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/@rechargeposapp"
                    target="_blank"
                  >
                    <i className="ri-youtube-line" />
                  </a>
                </li>
                <li>
                  <a href="https://instagram.com/rechargepos" target="_blank">
                    <i className="ri-instagram-line" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Copyright Warp End */}
      </>
    </React.Fragment>
  );
};

export default Footer;
