import React, { useContext } from "react";
import { BrowserRouter, Routes, Route, withRouter } from "react-router-dom";

import Home from "./Pages/Home/Home";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy/RefundPolicy";
import GrievancePolicy from "./Pages/GrievancePolicy/GrievancePolicy";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import ContactUs from "./Pages/ContactUs/ContactUs";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OurServices from "./Pages/OurServices/OurServices";

import LoadingContext from "./Store/loading-context";
import Loading from "./Pages/Loading/Loading";
// import Footer from "./Pages/Templete/Footer";

import { FloatingWhatsApp } from "react-floating-whatsapp";

const App = () => {
  const loadingCtx = useContext(LoadingContext);

  return (
    <React.Fragment>
      {loadingCtx.isLoading && <Loading />}

      <FloatingWhatsApp
        phoneNumber={917890251220}
        accountName="Recharge POS"
        avatar="/images/favicon.png"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />

      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="contact_us" element={<ContactUs />} />
          <Route path="terms_and_conditions" element={<TermsAndConditions />} />
          <Route path="refund_policy" element={<RefundPolicy />} />
          <Route path="privacy_policy" element={<PrivacyPolicy />} />
          <Route path="grievance_policy" element={<GrievancePolicy />} />
          <Route path="about_us" element={<AboutUs />} />
          <Route path="our_services" element={<OurServices />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
