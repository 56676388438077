import React from "react";

const GetInTouch = () => {
  return (
    <React.Fragment>
      {/* Stand Warp Start */}
      <div className="stand-warp">
        <div className="container">
          <div className="inner-stand-warp d-flex align-items-center justify-content-between">
            <div className="content">
              <span className="title">Have any question?</span>
              <h2>
                If you have any question about our product, service, payment or
                company, please contact us.
              </h2>
            </div>
            <div className="stand-btn">
              <a href="/contact_us" className="default-btn white-color">
                Contact Us Now
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Stand Warp End */}
    </React.Fragment>
  );
};

export default GetInTouch;
