import React from "react";

const HowItWorks = () => {
  return (
    <>
      {/* Assistant Warp Start */}
      <div className="assistant-warp ptb-100 bg-F8F9FC">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="assistant-image">
                <img src="images/app2.png" className="assistant1" alt="image" />
                <img
                  src="assets/images/shapes/assistent-shape1.png"
                  className="assistant-shape1"
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="assistant-content single-section style6">
                <h2>Create an account - 3 easy steps</h2>
                <p>
                  Get Started in Minutes with Our Simple Registration Process!
                </p>
                <ul className="assistant-list">
                  <li>
                    <div className="icon bg1">
                      <span>01</span>
                    </div>
                    <div className="content">
                      <h6>Download Application</h6>
                      <p>
                        Head to your app store and search for Recharge POS App.
                        Tap “Download” to get it installed on your device.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg2">
                      <span>02</span>
                    </div>
                    <div className="content">
                      <h6>Quick Registration</h6>
                      <p>
                        Open the app and follow the easy sign-up process. Sign
                        up free quickly with just a few details.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon bg3">
                      <span>03</span>
                    </div>
                    <div className="content">
                      <h6>All set! Enjoy a seamless recharge experience</h6>
                      <p>
                        Congratulations! Your account is now ready. Start
                        exploring all the features and enjoy seamless recharges
                        right away. .
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Assistant Warp End */}
    </>
  );
};

export default HowItWorks;
