import React from "react";

const AppFeatures = () => {
  return (
    <>
      {/* Features Warp Start */}
      <div className="features-warp pt-100 pb-75 ">
        <div className="container">
          <div className="section-title style6">
            <h2>Recharge POS App Features</h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg1">
                  <i class="fas fa-mobile-alt"></i>
                </div>
                <h3>
                  <a href="#">Instant Recharges</a>
                </h3>
                <p>
                  Top up mobile, DTH, and utility services quickly and securely,
                  with real-time confirmations.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg2">
                  <i class="fas fa-rupee-sign"></i>
                </div>
                <h3>
                  <a href="#">Earn Upto 4% Commissions</a>
                </h3>
                <p>
                  Get upto 4% instant commissions credited to wallet on every
                  mobile and dth recharge.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg3">
                  <i class="fas fa-money-bill-wave"></i>
                </div>
                <h3>
                  {" "}
                  <a href="#">Secure UPI Payments</a>
                </h3>
                <p>
                  Make all your recharges seamlessly using UPI, ensuring quick,
                  hassle-free, and secure transactions.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg4">
                  <i class="fas fa-user"></i>
                </div>
                <h3>
                  <a href="#">User-Friendly Interface</a>
                </h3>
                <p>
                  Navigate effortlessly through a simple and intuitive design,
                  making transactions smooth and easy.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg5">
                  <i class="fas fa-percentage"></i>
                </div>
                <h3>
                  <a href="#">Exclusive Offers</a>
                </h3>
                <p>
                  Access special discounts, cashback, and offers on mobile
                  recharges, DTH, and bill payments.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg6">
                  <i class="fas fa-question-circle"></i>
                </div>
                <h3>
                  <a href="#">24/7 Support</a>
                </h3>
                <p>
                  Get access to round-the-clock customer support to help with
                  any issues or queries.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg7">
                  <i class="fas fa-receipt"></i>
                </div>
                <h3>
                  <a href="#">Real-Time Transaction</a>
                </h3>
                <p>
                  Get instant confirmation for every recharge and payment,
                  ensuring transparency and peace of mind.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="features-box">
                <div className="icon bg8">
                  <i class="fas fa-shield-alt"></i>
                </div>
                <h3>
                  <a href="#">Secure Transactions</a>
                </h3>
                <p>
                  Enjoy complete peace of mind with end-to-end encrypted, safe,
                  and secure transactions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Warp End */}
    </>
  );
};

export default AppFeatures;
