import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";
import ServicesBoxIcon from "./ServicesBoxIcon";

const OurServices = () => {
  useEffect(() => {
    document.title = "Our Services - Recharge POS";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style3" />

        <div className="inner-hero-warp">
          <div className="container">
            <div className="inner-hero-content">
              <h1>Our Services</h1>
              <p>
                Explore Our Range of Fast and Reliable Recharge Services
                Tailored for You.
              </p>
            </div>
          </div>
        </div>

        <>
          {/* Service Warp Start */}
          <div className="service-warp pt-100 pb-75">
            <div className="container">
              <div className="section-title">
                <h2>What We Provide</h2>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="business-card">
                    <div className="icon">
                      <i class="fas fa-mobile-alt"></i>
                    </div>
                    <div className="content">
                      <h3>Mobile Recharge</h3>
                      <p>
                        Recharge POS offers quick, secure mobile recharges for
                        all major telecom operators in India, ensuring seamless
                        connectivity anytime, anywhere.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="business-card">
                    <div className="icon">
                      <i class="fas fa-tv"></i>
                    </div>
                    <div className="content">
                      <h3>DTH Recharge</h3>
                      <p>
                        Recharge POS provides fast and easy DTH recharges for
                        all major service providers, ensuring uninterrupted
                        entertainment at your convenience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="business-card">
                    <div className="icon">
                      <i class="fab fa-google-play"></i>
                    </div>
                    <div className="content">
                      <h3>Google Play Recharge</h3>
                      <p>
                        Recharge POS offers quick and secure Google Play
                        recharges, giving you instant access to apps, games, and
                        more, anytime you need.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <div className="business-card">
                    <div className="icon">
                      <i class="fas fa-file-invoice"></i>
                    </div>
                    <div className="content">
                      <h3>Utility Bill Payments</h3>
                      <p>
                        Recharge POS provides fast and secure utility payments,
                        ensuring you never miss a due date. Pay for electricity,
                        gas, and more with ease, anytime you need.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="service pt-5">
                <h3>Mobile Recharge</h3>
                <p>
                  Recharge POS offers fast and secure mobile recharges, allowing
                  you to instantly top up your balance anytime, anywhere. With a
                  user-friendly interface, you can choose from a wide range of
                  prepaid and postpaid plans from various networks. Enjoy
                  seamless transactions, real-time confirmation, and exclusive
                  offers to ensure you never run out of talk time or data!
                </p>
                <ul>
                  <li>Instant recharges for all major operators.</li>
                  <li>Safe and secure payment options.</li>
                  <li>Access to special discounts and cashback offers.</li>
                  <li>24/7 customer support for all your queries.</li>
                </ul>
              </div>

              <div class="service">
                <h3>DTH Recharge</h3>
                <p>
                  With Recharge POS, you can easily recharge your DTH services
                  in just a few clicks. Stay entertained with your favorite
                  channels by ensuring your DTH subscription is always active
                  and hassle-free. Our platform supports multiple DTH service
                  providers, giving you the flexibility to manage your
                  subscriptions effectively.
                </p>
                <ul>
                  <li>Quick and hassle-free DTH recharge process.</li>
                  <li>Wide selection of plans from all major providers.</li>
                  <li>Secure payment methods for peace of mind.</li>
                  <li>Notifications for upcoming subscription renewals.</li>
                </ul>
              </div>

              <div class="service">
                <h3>Google Play Recharge</h3>
                <p>
                  Recharge POS provides quick and secure Google Play recharges,
                  giving you instant access to apps, games, and more, anytime
                  you need. Whether you want to buy a premium app or make in-app
                  purchases, our platform ensures a smooth and efficient
                  process.
                </p>
                <ul>
                  <li>Instant Google Play credits for all your needs.</li>
                  <li>Safe and reliable payment methods.</li>
                  <li>Regular promotions and discounts on recharges.</li>
                  <li>24/7 customer support to assist you.</li>
                </ul>
              </div>

              <div class="service">
                <h3>Utility Bill Payments</h3>
                <p>
                  Recharge POS offers fast and secure utility bill payments,
                  ensuring you never miss a due date. Pay for electricity,
                  water, gas, and more with ease, anytime you need. Our platform
                  simplifies the payment process, allowing you to track your
                  bills and receive timely notifications for upcoming payments.
                </p>
                <ul>
                  <li>Pay all major utility bills in one place.</li>
                  <li>Easy tracking of payment history.</li>
                  <li>Secure transactions with multiple payment options.</li>
                  <li>Get reminders for due dates to avoid late fees.</li>
                </ul>
              </div>
            </div>
          </div>
          {/* Service Warp End */}
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default OurServices;
