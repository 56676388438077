import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";

  return (
    <React.Fragment>
      <>
        {/* Preloader Area Start */}
        {/* <div
          className="preloader-area position-fixed text-center bg6"
          id="preloader"
        >
          <div className="cube-wrapper">
            <div className="cube-folding">
              <span className="leaf1" />
              <span className="leaf2" />
              <span className="leaf3" />
              <span className="leaf4" />
            </div>
            <span className="loading" data-name="Loading">
              Rible
            </span>
          </div>
        </div> */}
        {/* Preloader Area End */}

        {/* Start Navbar Area */}
        <nav
          className={`navbar navbar-expand-lg top-navbar ${headerType}`}
          id="navbar"
        >
          <div className="container-fluid style6">
            <a className="navbar-brand" href="/">
              <img
                src="/images/logo.png"
                className="navbar-logo number-6"
                alt="logo"
                width={180}
              />
              <img
                src="/images/logo.png"
                className="responsive-logo"
                alt="logo"
                width={180}
              />
            </a>
            <a
              className="navbar-toggler"
              data-bs-toggle="offcanvas"
              href="#navbarOffcanvas"
              role="button"
              aria-controls="navbarOffcanvas"
            >
              <span className="burger-menu">
                <span className="top-bar" />
                <span className="middle-bar" />
                <span className="bottom-bar" />
              </span>
            </a>
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mx-auto style2">
                <li className="nav-item">
                  <a href="/" className="nav-link active">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/about_us" className="nav-link">
                    About Us
                  </a>
                </li>
                <li className="nav-item ">
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle nav-link"
                  >
                    Services
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="/our_services" className="nav-link">
                        Mobile Recharge
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/our_services" className="nav-link">
                        DTH Recharge
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/our_services" className="nav-link">
                        Google Play Recharge
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/our_services" className="nav-link">
                        Utility Bill Payments
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item ">
                  <a
                    href="javascript:void(0)"
                    className="dropdown-toggle nav-link"
                  >
                    Pages
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item">
                      <a href="/terms_and_conditions" className="nav-link">
                        Terms and Conditions
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/privacy_policy" className="nav-link">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/refund_policy" className="nav-link">
                        Refund Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/grievance_policy" className="nav-link">
                        Grievance Policy
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <a href="/contact_us" className="nav-link ">
                    Contact
                    <span>Us</span>{" "}
                  </a>
                </li>
              </ul>
              <div className="others-option d-flex align-items-center">
                <div className="option-item">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.fispl.rechargepos"
                    target="_blank"
                    className="default-btn white-color"
                  >
                    Download App
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* End Navbar Area */}

        {/*  Start Mobile Device Navbar Area */}
        <div
          className="responsive-navbar offcanvas offcanvas-end"
          tabIndex={-1}
          id="navbarOffcanvas"
        >
          <div className="offcanvas-header">
            <a href="index.html" className="logo d-inline-block">
              <img src="images/logo.png" alt="logo" width={150} />
            </a>
            <button
              type="button"
              className="close-btn"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{ paddingLeft: "20px" }}
            >
              <i className="ri-close-line" />
            </button>
          </div>
          <div className="offcanvas-body">
            <div className="accordion" id="navbarAccordion">
              <div className="accordion-item">
                <a className="accordion-button without-icon" href="/">
                  Home
                </a>
              </div>

              <div className="accordion-item">
                <a className="accordion-button without-icon" href="/">
                  About Us
                </a>
              </div>

              <div className="accordion-item">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo332"
                  aria-expanded="false"
                  aria-controls="collapseTwo332"
                >
                  Services
                </button>
                <div
                  id="collapseTwo332"
                  className="accordion-collapse collapse"
                  data-bs-parent="#navbarAccordion22"
                >
                  <div className="accordion-body">
                    <div className="accordion" id="navbarAccordion22">
                      <div className="accordion-item">
                        <a href="/our_services" className="accordion-link">
                          Mobile Recharge
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/our_services" className="accordion-link">
                          DTH Recharge
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/our_services" className="accordion-link">
                          Google Play Recharge
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/our_services" className="accordion-link">
                          Utility Bill Payments
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="accordion-item">
                <button
                  className="accordion-button collapsed "
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  Pages
                </button>
                <div
                  id="collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#navbarAccordion"
                >
                  <div className="accordion-body">
                    <div className="accordion" id="navbarAccordion25">
                      <div className="accordion-item">
                        <a
                          href="/terms_and_conditions"
                          className="accordion-link "
                        >
                          Terms and Conditions
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/privacy_policy" className="accordion-link ">
                          Privacy Policy
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/refund_policy" className="accordion-link ">
                          Refund Policy
                        </a>
                      </div>
                      <div className="accordion-item">
                        <a href="/grievance_policy" className="accordion-link ">
                          Grievance Policy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <a className="accordion-button without-icon" href="/contact_us">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="others-option d-flex align-items-center">
              <div className="option-item">
                <a
                  href="https://play.google.com/store/apps/details?id=com.fispl.rechargepos"
                  target="_blank"
                  className="default-btn white-color"
                >
                  Download App
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* End Mobile Device Navbar Area */}
      </>
    </React.Fragment>
  );
};

export default Header;
