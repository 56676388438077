import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const GrievancePolicy = () => {
  useEffect(() => {
    document.title = "Grievance Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style3" />

        <div className="inner-hero-warp">
          <div className="container">
            <div className="inner-hero-content">
              <h1>Grievance Policy</h1>
              <p></p>
            </div>
          </div>
        </div>

        <div class="terms-conditions-area pt-100 pb-75">
          <div class="container">
            <div class="terms-conditions">
              <div class="conditions-content">
                <div className="info">
                  <div className="pb-3">
                    <h4>Grievance Policy for Recharge POS</h4>
                  </div>
                  <p>
                    In accordance with Information Technology Act 2000 and rules
                    made there under, contact details of the Grievance Officer
                    are provided below:
                  </p>
                  {/* <p>Name:</p> */}
                  <p>
                    Address: Formax It Solutions Pvt Ltd, Ground Floor, 16,
                    Mochpole, Near Junior School, Nabapally, Barasat, Kolkata -
                    700126
                  </p>
                  {/* <p>Mobile:</p> */}
                  <p>Email: helpdesk@rechargepos.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GrievancePolicy;
