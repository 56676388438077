import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - Recharge POS";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style3" />

        <div className="inner-hero-warp">
          <div className="container">
            <div className="inner-hero-content">
              <h1>About US</h1>
              <p>Your Recharge, Your Commission.</p>
            </div>
          </div>
        </div>

        <>
          {/* About Help Warp Start */}
          <div className="about-help-warp">
            <div className="container">
              <div className="inner-about-warp ptb-100">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="about-content">
                      <h3>Who We Are</h3>
                      <p>
                        Recharge POS is a dynamic platform dedicated to
                        simplifying the recharge process for mobile and DTH
                        services while providing users with the opportunity to
                        earn commissions. Our team is passionate about
                        leveraging technology to create a user-friendly
                        experience that empowers individuals and businesses
                        alike. With a focus on reliability and speed, we strive
                        to be the go-to solution for all recharge needs.
                      </p>
                      <h4>Our Vision</h4>
                      <p>
                        Our vision is to become the leading platform in the
                        recharge industry, recognized for our commitment to
                        innovation, user satisfaction, and profitability. We aim
                        to revolutionize the way people manage their payments,
                        making it effortless and rewarding for everyone.
                      </p>
                      <h4>Our Mission</h4>
                      <p>
                        Our mission is to provide fast, secure, and convenient
                        recharge services that enable users to earn commissions
                        on every transaction. We are dedicated to enhancing our
                        platform continuously, ensuring that our users enjoy the
                        best possible experience while maximizing their
                        earnings.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="about-image">
                      <img
                        src="assets/images/heros/about2.jpg"
                        className="about2"
                        alt="about"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About Help Warp End */}
        </>

        <GetInTouch />
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
