import React from "react";
import Footer from "../Templete/Footer";
// import NavBar from "../Templete/NavBar";
// import AboutCompany from "./AboutCompany";
// import BusinessPartner from "./BusinessPartner";
// import DownloadApp from "./DownloadApp";
import StartHero from "./StartHero";
// import Testimonials from "./Testimonials";

// import ServicesBoxIcon from "../OurServices/ServicesBoxIcon";

import Header from "../Templete/Header";
import GetInTouch from "./GetInTouch";
import AppFeatures from "./AppFeatures";
import HowItWorks from "./HowItWorks";

const Home = () => {
  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="style6" />
        <StartHero />
        <AppFeatures />
        <HowItWorks />
        {/* <AboutCompany />
        <ServicesBoxIcon />

        <Testimonials />*/}
        <div style={{ marginTop: "100px" }}></div>
        <GetInTouch />
        <Footer />
      </div>

      {/* <BusinessPartner />
      <AboutFormaxPay />
      <DownloadApp />
      */}
    </React.Fragment>
  );
};

export default Home;
